var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "联盟未付",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "fixed-container"
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入商家名称"
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.business_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "business_name", $$v);
      },
      expression: "listQuery.business_name"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总未付",
      "value": '￥' + _vm.totalUnpaidAmount,
      "value-class": "cell-value",
      "center": ""
    }
  })], 1), _c('div', [_c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (business) {
    return _c('van-cell', {
      key: business.id,
      attrs: {
        "title": business.name,
        "value-class": "cell-value",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toDetailView(business.id);
        }
      }
    }, [business.unpaid_amount > 0 ? _c('div', [_vm._v("￥" + _vm._s(business.unpaid_amount))]) : _vm._e(), _c('div', {
      attrs: {
        "slot": "label"
      },
      slot: "label"
    }, [business.return_amount < 0 ? _c('div', [_vm._v("退货：￥" + _vm._s(business.return_amount))]) : _vm._e()])]);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }