<template>
  <div>
    <my-nav-bar
      title="联盟未付"
      left-text="返回"
      left-arrow
    />
    <div class="fixed-container">
      <van-search v-model="listQuery.business_name" placeholder="请输入商家名称" @search="onSearch" />
      <van-cell title="总未付" :value="'￥' + totalUnpaidAmount" value-class="cell-value" center />
    </div>
    <div>
      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <van-empty v-if="showEmpty" description="暂无数据" />
        <!-- <van-swipe-cell
          v-for="(business) in list"
          :key="business.id"
        > -->
        <van-cell
          v-for="(business) in list"
          :key="business.id"
          :title="business.name"
          value-class="cell-value"
          is-link
          center
          @click="toDetailView(business.id)"
        >
          <div v-if="business.unpaid_amount > 0">￥{{ business.unpaid_amount }}</div>
          <div slot="label">
            <div v-if="business.return_amount < 0">退货：￥{{ business.return_amount }}</div>
          </div>
        </van-cell>
        <!-- <template #right>
            <van-button style="height: 100%" square type="primary" text="欠条" @click="toIouView(business)" />
          </template>
        </van-swipe-cell> -->
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getBusinessUnpaids } from '@/api/business-unpaid'

export default {
  name: 'BusinessUnpaid',
  components: { myNavBar },
  data() {
    return {
      loading: false,
      finished: false,
      listQuery: {
        page: 0,
        limit: 10,
        business_name: ''
      },
      list: [],
      showEmpty: false,
      totalUnpaidAmount: 0
    }
  },
  created() {
    this.$store.commit('setKeepAlive', this.$options.name)
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/home') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      this.showEmpty = false
      getBusinessUnpaids(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
        this.totalUnpaidAmount = res.data.total_unpaid_amount
      })
    },
    toDetailView(businessId) {
      this.$router.push({
        path: 'business-unpaid-detail',
        query: {
          business_id: businessId
        }
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.show = false
      this.$refs.list.check()
    },
    onSearch() {
      this.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color.scss";
  .cell-value {
    color: $amountRedColor;
    flex: 2;
  }
  .fixed-container {
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .list {
    margin-top: 154px;
  }
  .tips {
    padding: 10px;
    color: $grayTextColor;
  }
  .payment-container {
    padding: 10px;
  }
</style>
